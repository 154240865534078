<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="魅力下限" prop="limit" >
        <a-input v-model="form.limit" placeholder="请输入魅力下限" />
      </a-form-model-item>
      <a-form-model-item label="特权logo" prop="logo" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange"
        >
          <img v-if="form.logo" :src="form.logo" alt="图片" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="具体等级" prop="level" >
        <a-input v-model="form.level" placeholder="请输入具体等级" />
      </a-form-model-item>
      <a-form-model-item label="类型" prop="type">
        <a-select v-model="form.type" placeholder="请选择类型" @change="charismaInit">
          <a-select-option :value="0">大等级</a-select-option>
          <a-select-option :value="1">小等级</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="特权等级名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入特权等级名称" />
      </a-form-model-item>

      <a-form-model-item label="上一等级" prop="preLevel">
        <a-select v-model="form.preLevel" placeholder="请选择上一等级">
          <a-select-option v-for="(item,key) in charismaList" :key="item.name" :value="item.id">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="头像光圈图片" prop="avatarCircle" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest2'
          :beforeUpload="beforeUpload2"
          @change="handleChange2"
        >
          <img v-if="form.avatarCircle" :src="form.avatarCircle" alt="图片" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {
  getConfiguration,
  addConfiguration,
  updateConfiguration,
  listConfiguration,
  pageConfiguration
} from '@/api/biz/charismaLevel'
import UploadFileToOSS from "@/utils/upload-file-to-oss";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      charismaList: [],
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        limit: null,

        logo: null,

        level: null,

        name: null,

        preLevel: null,

        nextLevel: null,

        avatarCircle: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.logo = info.file.originFileObj.ossUrl;
      }
    },
    fileUploadRequest2(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload2: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    handleChange2(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.avatarCircle = info.file.originFileObj.ossUrl;
      }
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        limit: null,
        logo: null,
        level: null,
        name: null,
        preLevel: null,
        nextLevel: null,
        avatarCircle: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    charismaInit(type){
      let param = {
        'type' : type
      }
      listConfiguration(param).then(response => {
        this.charismaList = response.data
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getConfiguration({"id":id}).then(response => {
        this.form = response.data
        let type = response.data.type;
        this.charismaInit(type);

        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateConfiguration(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addConfiguration(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
