import request from '@/utils/request'


// 查询魅力等级列表
export function listConfiguration(query) {
  return request({
    url: '/biz/charismaLevel/list',
    method: 'get',
    params: query
  })
}

// 查询魅力等级分页
export function pageConfiguration(query) {
  return request({
    url: '/biz/charismaLevel/page',
    method: 'get',
    params: query
  })
}

// 查询魅力等级详细
export function getConfiguration(data) {
  return request({
    url: '/biz/charismaLevel/detail',
    method: 'get',
    params: data
  })
}

// 新增魅力等级
export function addConfiguration(data) {
  return request({
    url: '/biz/charismaLevel/add',
    method: 'post',
    data: data
  })
}

// 修改魅力等级
export function updateConfiguration(data) {
  return request({
    url: '/biz/charismaLevel/edit',
    method: 'post',
    data: data
  })
}

// 删除魅力等级
export function delConfiguration(data) {
  return request({
    url: '/biz/charismaLevel/delete',
    method: 'post',
    data: data
  })
}
